<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar card>
        <v-select
          v-model="year"
          :items="years"
          label="Year"
          prepend-inner-icon="event"
        />
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            icon
            @click="showChart = !showChart"
          >
            <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-bar' }}</v-icon>
          </v-btn>
          <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-slide-y-transition>
        <v-card-text v-show="showChart">
          <chart
            :options="chartOptions"
            :series="series"
            type="bar"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="12"
          class="px-1 pb-1"
          item-key="month"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-center">
                {{ props.item.month }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b10 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b59 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b59db }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89db }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b54db }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b149 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.nu }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b39_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b54db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b59db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b150_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wawa18 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wawa35 }}
              </td>
              <td class="text-xs-center">
                {{ props.item.black }}
              </td>
              <td class="text-xs-center">
                {{ props.item.prepaid }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.total }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
&nbsp;
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b10 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b59 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b59db }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89db }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b54db }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b149 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.nu }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b39_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b54db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b59db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b150_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wawa18 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wawa35 }}
              </td>
              <td class="text-xs-center info">
                {{ totals.black }}
              </td>
              <td class="text-xs-center info">
                {{ totals.prepaid }}
              </td>
              <td class="text-xs-center info">
                {{ totals.total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import apexcharts from '@/mixins/apexcharts'

const reportTitle = 'Monthly Activation & Plan Distribution'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
    Chart: () => import(/* webpackChunkName: "chart" */ '@/components/Chart'),
  },
  mixins: [apexcharts],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Monthly Excerpt', disabled: false, to: '/management/monthly_view',
        },
        {
          text: 'Monthly Activation', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'center',
          sortable: false,
          text: 'Month',
          value: 'month',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B39',
          value: 'b39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B59(E)/B39+20',
          value: 'b59',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B59DB',
          value: 'b59db',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B89/B39+50',
          value: 'b89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B89DB',
          value: 'b89db',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B54DB',
          value: 'b54db',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B149',
          value: 'b149',
        },
        {
          align: 'center',
          sortable: false,
          text: 'NU',
          value: 'nu',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B150',
          value: 'b150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B39_FC',
          value: 'b39_fc',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B54DB_FC',
          value: 'b54db_fc',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B59DB_FC',
          value: 'b59db_fc',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B89DB_FC',
          value: 'b89db_fc',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B150_FC',
          value: 'b150_fc',
        },
        {
          align: 'center',
          sortable: false,
          text: 'WAWA18',
          value: 'wawa18',
        },
        {
          align: 'center',
          sortable: false,
          text: 'WAWA35',
          value: 'wawa35',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Black',
          value: 'black',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Prepaid',
          value: 'prepaid',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total',
          value: 'total',
        },
      ],
      items: [],
      loading: false,
      menu: false,
      year: null,
      years: [],
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    series: function () {
      const series = [
        { name: 'Prepaid', data: [] },
        { name: 'Black', data: [] },
      ]

      for (let i = 0; i < this.filteredItems.length; i++) {
        series[0].data.push(this.filteredItems[i].prepaid)
        series[1].data.push(this.filteredItems[i].black)
      }

      return series
    },
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          month: this.onexox[i].month,
          prepaid: this.onexox[i].prepaid + (this.xox[i] ? this.xox[i].prepaid : 0),
          black: this.onexox[i].black + (this.xox[i] ? this.xox[i].black : 0),
          b10: this.onexox[i].b10 + (this.xox[i] ? this.xox[i].b10 : 0),
          b39: this.onexox[i].b39 + (this.xox[i] ? this.xox[i].b39 : 0),
          b59: this.onexox[i].b59 + (this.xox[i] ? this.xox[i].b59 : 0),
          b59db: this.onexox[i].b59db + (this.xox[i] ? this.xox[i].b59db : 0),
          b89: this.onexox[i].b89 + (this.xox[i] ? this.xox[i].b89 : 0),
          b89db: this.onexox[i].b89db + (this.xox[i] ? this.xox[i].b89db : 0),
          b54db: this.onexox[i].b54db + (this.xox[i] ? this.xox[i].b54db : 0),
          b149: this.onexox[i].b149 + (this.xox[i] ? this.xox[i].b149 : 0),
          nu: this.onexox[i].nu + (this.xox[i] ? this.xox[i].nu : 0),
          b150: this.onexox[i].b150 + (this.xox[i] ? this.xox[i].b150 : 0),
          b39_fc: this.onexox[i].b39_fc + (this.xox[i] ? this.xox[i].b39_fc : 0),
          b54db_fc: this.onexox[i].b54db_fc + (this.xox[i] ? this.xox[i].b54db_fc : 0),
          b59db_fc: this.onexox[i].b59db_fc + (this.xox[i] ? this.xox[i].b59db_fc : 0),
          b89db_fc: this.onexox[i].b89db_fc + (this.xox[i] ? this.xox[i].b89db_fc : 0),
          b150_fc: this.onexox[i].b150_fc + (this.xox[i] ? this.xox[i].b150_fc : 0),
          wawa18: this.onexox[i].wawa18 + (this.xox[i] ? this.xox[i].wawa18 : 0),
          wawa35: this.onexox[i].wawa35 + (this.xox[i] ? this.xox[i].wawa35 : 0),
          total: this.onexox[i].total + (this.xox[i] ? this.xox[i].total : 0),
        })
      }

      return all
    },
    filteredItems: function () {
      if (this.filter === 'OneXOX') return this.onexox
      else if (this.filter === 'XOX') return this.xox
      else return this.all
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        prepaid: 0,
        black: 0,
        b10: 0,
        b39: 0,
        b59: 0,
        b59db: 0,
        b89: 0,
        b89db: 0,
        b54db: 0,
        b149: 0,
        nu: 0,
        b150: 0,
        b39_fc: 0,
        b54db_fc: 0,
        b59db_fc: 0,
        b89db_fc: 0,
        b150_fc: 0,
        wawa18: 0,
        wawa35: 0,
        total: 0,
      }

      for (const item of items) {
        totals.prepaid += item.prepaid
        totals.black += item.black
        totals.b10 += item.b10
        totals.b39 += item.b39
        totals.b59 += item.b59
        totals.b59db += item.b59db
        totals.b89 += item.b89
        totals.b89db += item.b89db
        totals.b54db += item.b54db
        totals.b149 += item.b149
        totals.nu += item.nu
        totals.b150 += item.b150
        totals.b39_fc += item.b39_fc
        totals.b54db_fc += item.b54db_fc
        totals.b59db_fc += item.b59db_fc
        totals.b89db_fc += item.b89db_fc
        totals.b150_fc += item.b150_fc
        totals.wawa18 += item.wawa18
        totals.wawa35 += item.wawa35
        totals.total += item.total
      }

      return totals
    },
  },
  watch: {
    year: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2014; year--) {
      this.years.push(year)
    }
    this.year = maxDate
    this.chartOptions.chart.type = 'bar'
    this.chartOptions.chart.stacked = true
    this.chartOptions.xaxis.type = 'category'
    this.chartOptions.xaxis.category = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ]
    this.chartOptions.xaxis.labels.formatter = val => this.chartOptions.xaxis.category[val - 1]
  },
  methods: {
    getActivations (year) {
      this.loading = true
      const params = createGetParams({
        year: year,
      })
      this.loading = true
      this.$rest.get('getActivationsByYear.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
